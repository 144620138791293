import React from "react";
import styled from "styled-components";
import { IconContext } from "react-icons";

import { GrStatusWarningSmall } from "react-icons/gr";

const Momentum = ({ trend }) => {
  let style = { color: "green", size: "30px" };
  if (trend === "down") {
    style.color = "red";
    style.transform = "rotate(180deg)";
  }
  return (
    <Wrapper>
      <IconContext.Provider
        value={{
          style,
        }}
      >
        <GrStatusWarningSmall />
      </IconContext.Provider>
    </Wrapper>
  );
};

const Wrapper = styled.span`
  position: relative;
  bottom: -2px;
`;

export default Momentum;
