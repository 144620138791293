import React from "react";
import styled from "styled-components";

import TransactionPopup from "./TransactionPopup";

const Popups = () => {
  return (
    <Wrapper>
      <TransactionPopup />
    </Wrapper>
  );
};

const Wrapper = styled.div``;

export default Popups;
