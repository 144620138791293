import React from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { GrLinkPrevious } from "react-icons/gr";
import UnstyledButton from "../UnstyledButton";

import { userZoomIn } from "../../actions";

const PlanetHeader = () => {
  const dispatch = useDispatch();

  return (
    <Wrapper>
      <BackButton
        onClick={() => {
          dispatch(userZoomIn(false));
        }}
      >
        <Circle />
        <ArrowIcon />
        <BtnText>back to sector</BtnText>
      </BackButton>
    </Wrapper>
  );
};
const Wrapper = styled.header`
  display: flex;
  align-items: center;
  justify-content: start;
`;

const Circle = styled.div`
  position: absolute;
  height: 26px;
  width: 26px;
  left: 10px;
  top: 2px;
  border-radius: 100%;
  background-color: rgba(0, 0, 0, 0);
  transition: all 100ms;
`;

const BackButton = styled(UnstyledButton)`
  padding: 4px;
  margin-right: 4px;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    & div {
      background-color: rgba(0, 0, 0, 0.12);
    }
  }
`;

const BtnText = styled.p`
  margin-left: 4px;
`;

const ArrowIcon = styled(GrLinkPrevious)`
  margin-top: -1px;
  height: 18px;
  width: 18px;
`;

export default PlanetHeader;
