import React from "react";
import useInterval from "../hooks/use-interval.hook";
import { useSelector, useDispatch } from "react-redux";

import { harvestRandom } from "../Helpers/Utils";
import { harvestResource } from "../actions";

export const MiningContext = React.createContext(null);

const MiningProvider = ({ children }) => {
  const dispatch = useDispatch();

  const [isMining, setIsMining] = React.useState(false);
  const [completed, setCompleted] = React.useState(0);
  const [delay, setDelay] = React.useState(null);
  const [miningTime, setMiningTime] = React.useState(3000);

  const { currentLocation, currentSector } = useSelector(
    (state) => state.gameData
  );

  const planetData = currentSector[currentLocation];

  React.useEffect(() => {
    if (!isMining) {
      setDelay(null);
      setCompleted(0);
    } else {
      // console.log("we drillin!");
      setDelay(miningTime / 100);
    }
  }, [isMining, miningTime]);

  useInterval(() => {
    if (completed === 100) {
      setCompleted(1);
    } else {
      if (completed === 99) {
        setCompleted(completed + 1);

        let harvested = harvestRandom(planetData.resources);
        dispatch(harvestResource(harvested));
        setIsMining(false);
      } else {
        setCompleted(completed + 1);
      }
    }
  }, delay);

  return (
    <MiningContext.Provider value={{ setIsMining, completed, planetData }}>
      {children}
    </MiningContext.Provider>
  );
};

export default MiningProvider;
