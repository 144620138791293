import React from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import { RESOURCE_DATA } from "../../data/gameData";
import { COLORS } from "../../constants";

import coinsSrc from "../../assets/icons/coins_gold.svg";

import Popup from "../Popup";
import ValueGraph from "./ValueGraph";
import PurchaseArea from "./PurchaseArea";
import SellArea from "./SellArea";

import { cancelTransactionProcess } from "../../actions";

const TransactionPopup = () => {
  const dispatch = useDispatch();

  const { status, type, selectedResource } = useSelector(
    (state) => state.transaction
  );
  const { inventory } = useSelector((state) => state.gameData);

  const { marketData } = useSelector((state) => state.market);

  const [inputValue, setInputValue] = React.useState(1);

  const itemInInventory = inventory.find(
    (item) => item.name === selectedResource
  );

  if (status === "idle") {
    if (inputValue !== 1) {
      setInputValue(1);
    }
    return null;
  }
  // <Name>{RESOURCE_DATA[itemForSale.name].name}</Name>
  // <div>
  //   <Price> {itemForSale.priceHistory[0]} x </Price>
  const itemForSale = marketData.find((item) => item.name === selectedResource);

  return (
    <Popup
      dismiss={() => {
        dispatch(cancelTransactionProcess());
      }}
    >
      <AreaTitle> {RESOURCE_DATA[itemForSale.name].name} </AreaTitle>
      <ValueGraph resourceData={itemForSale} />
      <MarketValue>
        Current market price is <span>{itemForSale.priceHistory[0]}</span>
        <Icon src={coinsSrc} />
      </MarketValue>

      {type === "purchase" && (
        <PurchaseArea
          name={RESOURCE_DATA[itemForSale.name].name}
          inputValue={inputValue}
          setInputValue={setInputValue}
          itemForSale={itemForSale}
          status={status}
        />
      )}
      {type === "sale" && (
        <SellArea
          name={RESOURCE_DATA[itemForSale.name].name}
          inputValue={inputValue}
          setInputValue={setInputValue}
          itemInInventory={itemInInventory}
          itemForSale={itemForSale}
          status={status}
        />
      )}
    </Popup>
  );
};

const AreaTitle = styled.h3`
  text-align: center;
  margin-bottom: 15px;
  font-size: 1.2rem;
`;

const MarketValue = styled.h3`
  text-align: center;
  margin-top: -30px;
  display: flex;
  justify-content: center;

  & span {
    margin-left: 4px;
    color: ${COLORS.credits};
  }
`;

const Icon = styled.img`
  display: inline;
  height: 24px;
  width: 32px;
`;

export default TransactionPopup;
