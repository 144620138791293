import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import {
  requestMarketInfo,
  receiveMarketInfo,
  marketInfoError,
  requestMarketUpdate,
} from "../../actions";

import { fetchMarketData } from "../../Helpers/api-helpers";

import MarketItem from "./MarketItem";
import MarketWidget from "./MarketWidget";
import LoadingSpinner from "../LoadingSpinner";
import { RESOURCE_DATA } from "../../data/gameData";

const Market = () => {
  const dispatch = useDispatch();
  const { marketData, status, lastUpdate } = useSelector(
    (state) => state.market
  );
  const [marketExpand, setMarketExpand] = React.useState(false);

  const refreshMarket = () => {
    if (!lastUpdate || Date.now() - lastUpdate >= 1800000) {
      // console.log("it's been more than 1800000ms");
      dispatch(requestMarketUpdate());
      fetchMarketData()
        .then((data) => {
          const lastUpdate = Date.now();
          dispatch(
            receiveMarketInfo(data.pricesData, data.message, lastUpdate)
          );
        })
        .catch((err) => dispatch(marketInfoError(err)));
    } else {
      // console.log("it's been less than 1800000ms");
    }
  };

  React.useEffect(() => {
    if (!lastUpdate || Date.now() - lastUpdate >= 1800000) {
      // console.log("it's been more than 1800000ms");
      dispatch(requestMarketInfo());
      fetchMarketData()
        .then((data) => {
          const lastUpdate = Date.now();
          dispatch(
            receiveMarketInfo(data.pricesData, data.message, lastUpdate)
          );
        })
        .catch((err) => dispatch(marketInfoError(err)));
    }
    // console.log("it's been less than 1800000ms");
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [dispatch]);

  React.useEffect(() => {
    window.addEventListener("focus", refreshMarket);
    return () => {
      window.removeEventListener("focus", refreshMarket);
    };
  }, []);

  const marketStyles = marketExpand ? { height: "180px" } : { height: "64px" };

  const toggleExpand = () => {
    setMarketExpand(!marketExpand);
  };

  if (status === "loading" || !marketData) {
    return (
      <Content style={{ ...marketStyles, paddingTop: "18px" }}>
        <LoadingSpinner size={25} />
      </Content>
    );
  }

  return (
    <Content onMouseDown={toggleExpand} style={marketStyles}>
      {marketExpand && <ClickCatcher />}
      <MarketBanner>
        {marketData.map((item) => {
          if (marketExpand) {
            return (
              <MarketItem
                key={item.name}
                id={item.name}
                displayName={RESOURCE_DATA[item.name].name}
                iconSrc={RESOURCE_DATA[item.name].src}
                priceHistory={item.priceHistory}
              />
            );
          }
          return (
            <MarketWidget
              key={item.name}
              name={item.name}
              priceHistory={item.priceHistory}
              iconSrc={RESOURCE_DATA[item.name].src}
              onClick={toggleExpand}
            />
          );
        })}
      </MarketBanner>
      {marketExpand && <Title>Space Goods</Title>}
    </Content>
  );
};

const Content = styled.div`
  border-bottom: 4px double black;
  transition: all 550ms ease-out;
  overflow: hidden;
  background-color: white;
`;

const Title = styled.h3`
  margin-top: 5px;
  text-align: center;
`;

const MarketBanner = styled.ul`
  margin: 0 auto;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const ClickCatcher = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0);
`;

export default Market;
