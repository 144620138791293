import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

import { PLANETS_DATA } from "../../data/gameData";

import PlanetHeader from "./PlanetHeader";
import PlayerIcon from "../PlayerIcon";
import Mining from "./Mining";

const PlanetView = () => {
  const { currentLocation, currentSector } = useSelector(
    (state) => state.gameData
  );

  const planetData = currentSector[currentLocation];

  return (
    <Wrapper>
      <PlanetHeader />
      <PlanetName>currently orbiting: {planetData.name}</PlanetName>
      <PlanetDiv>
        <Mining planetData={planetData} />
        <Planet src={PLANETS_DATA[currentSector[currentLocation].color].src} />
        <PlayerIcon size={196} coordinates={[55, 10]} />{" "}
      </PlanetDiv>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  position: relative;
  padding-left: 10px;
  height: 100%;
  width: 100%;
`;

const PlanetName = styled.p`
  font-size: 24px;
`;
const PlanetDiv = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 150px;
`;

const Planet = styled.img`
  /* position: absolute;
  top: 200px;
  right: 10%; */
  height: 250px;
  width: 360px;
  border-radius: 100%;
  margin-right: 20px;
`;

export default PlanetView;
