//USER LOGIN ACTIONS
export const requestUserInfo = () => ({
  type: "USER_INFO_REQUEST",
});

export const receiveUserInfo = (accessToken, refreshToken) => ({
  type: "USER_INFO_RECEIVE",
  accessToken,
  refreshToken,
});

export const enterUsername = (id) => ({
  type: "ENTER_NAME",
  id,
});

export const userInfoError = (err) => ({
  type: "USER_INFO_FAILURE",
  err,
});

export const userSignOut = () => ({
  type: "USER_SIGN_OUT",
});

//GAME DATA ACTIONS
export const requestGameData = () => ({
  type: "GAME_DATA_REQUEST",
});

export const receiveGameData = (gameData, message) => ({
  type: "GAME_DATA_RECEIVE",
  gameData,
  message,
});
export const requestGameDataFailure = (error) => ({
  type: "GAME_DATA_RECEIVE",
  error,
});

export const harvestResource = (harvestedResource) => ({
  type: "HARVEST_RESOURCE",
  harvestedResource,
});

export const userZoomIn = (planetView) => ({
  type: "PLANET_VIEW",
  planetView,
});

export const planetTravel = (newLocation, travelCost) => ({
  type: "PLANET_TRAVEL",
  newLocation,
  travelCost,
});

export const purchaseFuelUnit = (amount, cost) => ({
  type: "PURCHASE_FUEL_UNIT",
  amount,
  cost,
});
export const upgradeMaxCargo = (amount, cost) => ({
  type: "UPGRADE_CARGO",
  amount,
  cost,
});
export const upgradeMaxFuel = (amount, cost) => ({
  type: "UPGRADE_FUEL_TANK",
  amount,
  cost,
});

export const travelToSector = (gameData, message) => ({
  type: "TRAVEL_TO_SECTOR",
  gameData,
  message,
});
export const addProgressFlag = (flag) => ({
  type: "ADD_FLAG",
  flag,
});

//MARKET ACTIONS
export const requestMarketInfo = () => ({
  type: "MARKET_DATA_REQUEST",
});

export const requestMarketUpdate = () => ({
  type: "MARKET_DATA_UPDATE",
});

export const receiveMarketInfo = (marketData, message, lastUpdate) => ({
  type: "MARKET_DATA_RECEIVE",
  marketData,
  message,
  lastUpdate,
});

export const marketInfoError = (err) => ({
  type: "MARKET_DATA_FAILURE",
  err,
});

//MARKET TRANSACTIONS ACTIONS
export const beginTransactionProcess = (transactionType, selectedResource) => ({
  type: "BEGIN_TRANSACTION_PROCESS",
  transactionType,
  selectedResource,
});
export const cancelTransactionProcess = () => ({
  type: "CANCEL_TRANSACTION_PROCESS",
});

export const requestMarketTransaction = () => ({
  type: "MARKET_TRANSACTION_REQUEST",
});

export const receiveMarketTransaction = (updatedGameData, message) => ({
  type: "MARKET_TRANSACTION_RECEIVE",
  updatedGameData,
  message,
});

export const marketTransactionError = (err) => ({
  type: "MARKET_TRANSACTION_FAILURE",
  err,
});

export const narrativeMessage = (message) => ({
  type: "NARRATIVE_MESSAGE",
  message,
});
