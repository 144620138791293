import { SHIPS_DATA } from "../data/gameData";

export const calcTotalLoad = (inventory) => {
  let totalLoad = 0;

  inventory.forEach((item) => {
    totalLoad += item.amount * item.size;
  });
  // console.log(totalLoad);
  return totalLoad;
};

export const calcNetworth = (gameData, marketData) => {
  let networth = 0;

  gameData.inventory.forEach((inventoryItem) => {
    const marketItem = marketData.find(
      (marketItem) => marketItem.name === inventoryItem.name
    );

    networth += inventoryItem.amount * marketItem.price;
  });

  networth += gameData.playerCash;
  networth -= gameData.playerDebt;

  return networth;
};

export const getMaxCargoSize = (playerShipData) => {
  const shipData = SHIPS_DATA.find(
    (item) => item.id === playerShipData.currentShip
  );
  return shipData.baseCargo + shipData.cargoStep * playerShipData.cargoLevel;
};

export const estimateAmount = (amount) => {
  if (amount < 5) {
    return "traces";
  }
  if (amount < 25) {
    return "some";
  }
  if (amount < 40) {
    return "several";
  }
  if (amount < 50) {
    return "a lot";
  }
  if (amount < 75) {
    return "a boatload";
  }
  return "omg wow";
};

export const harvestRandom = (resources) => {
  let totalResources = 0;
  let selectedResource;
  const resourceArray = Object.keys(resources);

  // console.log(resources);

  resourceArray.forEach((item) => {
    totalResources += Number(resources[item]);
  });

  let randomDraw = Math.floor(Math.random() * totalResources);

  for (let i = 0; i < resourceArray.length; i++) {
    //console.log(randomDraw, resourceArray[i], resources[resourceArray[i]]);
    if (randomDraw <= resources[resourceArray[i]]) {
      //console.log("should harvest", resourceArray[i]);
      selectedResource = resourceArray[i];
      i = resourceArray.length;
    }
    randomDraw -= resources[resourceArray[i]];
  }

  //console.log("harvesting", selectedResource);
  return selectedResource;
};

const sentence1 = (items, places) => {
  return `Maybe aim for that ${items[0]} `;
};

const sentence2 = (items, places) => {
  return `Try for this ${items[0]} ${places[0]}. `;
};

const sentence3 = (items, places) => {
  return `I see some ${items[0]} ${places[0]}. `;
};

const sentence4 = (items, places) => {
  return `Is that a ${items[0]} I saw move ${places[0]}? `;
};
const sentence5 = (items, places) => {
  return `Let's try ${places[0]}, maybe? `;
};
const sentence6 = (items, places) => {
  return `Let fate decide and go ${places[0]}. `;
};
const sentence7 = (items, places) => {
  return `I see a ${items[0]} ${places[0]}, ${places[1]}.`;
};

const returnsPlaces = (num) => {
  const placeList = [
    "over there",
    "over yonder",
    "here",
    "to your left",
    "portside",
    "on your 2 o'clock",
  ];
  let selectedPlaces = [];

  for (let i = 0; i < num; i++) {
    selectedPlaces.push(
      placeList.splice([Math.floor(Math.random() * placeList.length)], 1)
    );
  }

  return selectedPlaces;
};

const returnsItems = (num) => {
  const itemsList = [
    "supernova",
    "sun",
    "quadrant",
    "thing",
    "space whale",
    "pirate ship",
    "glittery movement",
    "gas giant",
    "brown dwarf",
    "red dwarf",
  ];
  let selectedItems = [];

  for (let i = 0; i < num; i++) {
    selectedItems.push(
      itemsList.splice([Math.floor(Math.random() * itemsList.length)], 1)
    );
  }

  return selectedItems;
};

const sentences = [
  {
    numItems: 1,
    numPlaces: 0,
    function: sentence1,
  },
  {
    numItems: 1,
    numPlaces: 1,
    function: sentence2,
  },
  {
    numItems: 1,
    numPlaces: 1,
    function: sentence3,
  },
  {
    numItems: 1,
    numPlaces: 1,
    function: sentence4,
  },
  {
    numItems: 0,
    numPlaces: 1,
    function: sentence5,
  },
  {
    numItems: 0,
    numPlaces: 1,
    function: sentence6,
  },
  {
    numItems: 1,
    numPlaces: 2,
    function: sentence7,
  },
];

export const hyperJumpSentence = () => {
  const randomSentence =
    sentences[Math.floor(Math.random() * sentences.length)];
  return randomSentence.function(
    returnsItems(randomSentence.numItems),
    returnsPlaces(randomSentence.numPlaces)
  );
};
