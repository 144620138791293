import React from "react";
import styled from "styled-components";

const ProgressBar = ({ completed }) => {
  const fillerStyle = {
    height: "100%",
    width: `${completed}%`,
    backgroundColor: "green",
    color: "white",
    borderRadius: "inherit",
    textAlign: "right",
    padding: "5px",
    opacity: completed === 0 ? 0 : 1,
  };

  return (
    <Wrapper>
      <div style={fillerStyle}>{completed !== 0 && `${completed}`}</div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 30px;
  width: 100%;
  background-color: #e0e0de;
  border-radius: 4px;
`;

export default ProgressBar;
