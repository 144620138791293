import React from "react";
import styled from "styled-components";

import UnstyledButton from "../UnstyledButton";

const InventoryItem = ({ iconSrc, amount, empty, isSelected, onClick }) => {
  if (empty) {
    return <EmptyWrapper />;
  }
  return (
    <Wrapper
      onClick={onClick}
      style={{
        border: isSelected ? "2px solid black" : "1px solid black",
        background: isSelected ? "lightyellow" : "white",
      }}
    >
      <Icon src={iconSrc} />
      <Amount>x{amount}</Amount>
    </Wrapper>
  );
};

const Wrapper = styled(UnstyledButton)`
  border: 1px solid black;
  border-radius: 10px;
  height: 75px;
  width: 75px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  outline: none;

  &:hover {
    cursor: pointer;
  }
`;

const EmptyWrapper = styled.div`
  border: 1px dashed black;
  border-radius: 10px;
  height: 75px;
  width: 75px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Icon = styled.img`
  height: 42px;
  width: 42px;
`;

const Amount = styled.span``;

export default InventoryItem;
