import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

import { GrMenu } from "react-icons/gr";

import SideMenu from "./SideMenu";

const Header = () => {
  const { status } = useSelector((state) => state.user);
  const { id } = useSelector((state) => state.gameData);
  const [menu, setMenu] = React.useState(false);

  return (
    <Wrapper>
      <Title>Looseleaf Galaxy</Title>
      <ProfileArea>
        {id !== "newPlayer" && status === "idle" && (
          <>
            <ProfileButton
              onClick={() => {
                setMenu(!menu);
              }}
            >
              <Icon>
                <GrMenu />
              </Icon>
            </ProfileButton>

            {/* onClick={handleSignOut} */}
          </>
        )}
      </ProfileArea>
      <SideMenu visible={menu} />
    </Wrapper>
  );
};

const Wrapper = styled.header`
  grid-column: 1 / span 2;
  grid-row: 1;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  min-height: 64px;
  border-bottom: 1px solid black;
`;

const ProfileArea = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 15%;
  min-width: 120px;
`;

const Title = styled.h1`
  text-align: center;
  font-weight: bold;

  & span {
    font-weight: 200;
    font-size: 16px;
  }
`;

const ProfileButton = styled.button`
  border-radius: 100%;
  outline: none;
  border: 1px black solid;
  height: 35px;
  width: 35px;
  background-color: lightgrey;

  &:hover {
    cursor: pointer;
    background-color: grey;
  }
`;

const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
`;

export default Header;
