import produce from "immer";
import { v4 as uuidv4 } from "uuid";

const initialState = {
  status: "loading",
};

export default function gameDataReducer(state = initialState, action) {
  // console.log(action);
  switch (action.type) {
    case "GAME_DATA_REQUEST": {
      // console.log(action);
      return produce(state, (draftState) => {
        draftState.status = "loading";
      });
    }
    case "GAME_DATA_RECEIVE": {
      // console.log(action);
      return { status: "idle", ...action.gameData };
    }
    case "GAME_DATA_FAILURE": {
      // console.log(action);
      return produce(state, (draftState) => {
        draftState.status = "error";
      });
    }
    case "MARKET_TRANSACTION_RECEIVE": {
      return produce(state, (draftState) => {
        draftState.inventory = action.updatedGameData.inventory;
        draftState.playerCash = action.updatedGameData.playerCash;
      });
    }

    case "PLANET_VIEW": {
      return produce(state, (draftState) => {
        draftState.planetView = action.planetView;
      });
    }

    case "ENTER_NAME": {
      return produce(state, (draftState) => {
        draftState.id = action.id;
        //change hash
        draftState.hash = uuidv4();
      });
    }

    case "ADD_FLAG": {
      return produce(state, (draftState) => {
        draftState.flags[action.flag] = true;
        //change hash
        draftState.hash = uuidv4();
      });
    }

    case "PLANET_TRAVEL": {
      return produce(state, (draftState) => {
        draftState.currentLocation = action.newLocation;
        draftState.playerFuel = state.playerFuel - action.travelCost;
        //change hash
        draftState.hash = uuidv4();
      });
    }
    case "TRAVEL_TO_SECTOR": {
      console.log(action);
      return produce(state, (draftState) => {
        draftState.currentLocation = action.gameData.currentLocation;
        draftState.currentSector = action.gameData.currentSector;
        draftState.playerFuel = action.gameData.playerFuel;
        //change hash
        draftState.hash = uuidv4();
      });
    }

    case "PURCHASE_FUEL_UNIT": {
      return produce(state, (draftState) => {
        draftState.playerCash = state.playerCash - action.cost;
        draftState.playerFuel = state.playerFuel + action.amount;
        //change hash
        draftState.hash = uuidv4();
      });
    }

    case "UPGRADE_CARGO": {
      return produce(state, (draftState) => {
        draftState.playerCash = state.playerCash - action.cost;
        draftState.shipData.cargoLevel = state.shipData.cargoLevel + 1;
        //change hash
        draftState.hash = uuidv4();
      });
    }

    case "UPGRADE_FUEL_TANK": {
      // console.log(state.shipData.fuelLevel);
      return produce(state, (draftState) => {
        draftState.playerCash = state.playerCash - action.cost;
        draftState.shipData.fuelLevel = state.shipData.fuelLevel + 1;
        //change hash
        draftState.hash = uuidv4();
      });
    }

    case "HARVEST_RESOURCE": {
      return produce(state, (draftState) => {
        if (
          draftState.currentSector[state.currentLocation].resources[
            action.harvestedResource
          ] === 1
        ) {
          delete draftState.currentSector[state.currentLocation].resources[
            action.harvestedResource
          ];
        } else {
          draftState.currentSector[state.currentLocation].resources[
            action.harvestedResource
          ] -= 1;
        }

        //change hash
        draftState.hash = uuidv4();
        //add to inventory too
        if (
          !draftState.inventory.find(
            (item) => item.name === action.harvestedResource
          )
        ) {
          draftState.inventory.push({
            name: action.harvestedResource,
            amount: 1,
            size: 1,
          });
        } else {
          draftState.inventory.find(
            (item) => item.name === action.harvestedResource
          ).amount += 1;
        }
      });
    }

    default:
      return state;
  }
}
