import React from "react";
import styled from "styled-components";

import { COLORS } from "../../constants";
import creditsSrc from "../../assets/icons/coins_gold.svg";
import fuelSrc from "../../assets/icons/atom_blue.svg";
import cargoSrc from "../../assets/icons/cargo.svg";

const Upgrade = ({ step, cost, canBuy, type, onClick, maxed }) => {
  let titleIconSrc = cargoSrc;

  if (type.includes("Fuel")) {
    titleIconSrc = fuelSrc;
  }

  if (maxed) {
    return (
      <Wrapper>
        <BuyUpgrade>
          <Name>
            <Icon src={titleIconSrc} />
          </Name>
          <p>Maxed!</p>
        </BuyUpgrade>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <BuyUpgrade disabled={!canBuy} onClick={onClick}>
        <Icon src={titleIconSrc} />
        <TextArea>
          <Name>{type}</Name>
          <Name>{` ( + ${step} )`}</Name>

          <Cost>
            {cost} <Icon src={creditsSrc} />
          </Cost>
        </TextArea>
      </BuyUpgrade>
    </Wrapper>
  );
};

const Wrapper = styled.li`
  padding: 5px 15px;
  font-size: 16px;
  list-style: none;
`;

const TextArea = styled.div`
  padding-top: 5px;
`;

const BuyUpgrade = styled.button`
  padding: 5px 18px;
  font-size: 16px;
  width: 175px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 200px;
  border: 2px transparent;

  transition: all 100ms;
  &:hover {
    &:enabled {
      transform: scale(1.1);
    }
    background-color: rgba(0, 0, 0, 0.12);
    cursor: pointer;
  }

  &:disabled {
    & p {
      color: red;
    }
  }
`;

const Name = styled.h4`
  color: black;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Cost = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: ${COLORS.credits};
`;

const Icon = styled.img`
  height: 38px;
  width: 38px;
`;

export default Upgrade;
