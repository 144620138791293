import React from "react";
import styled from "styled-components";
import playerIcon from "../assets/icons/rocket.svg";

const PlayerIcon = ({ coordinates, size }) => {
  const posX = coordinates[0];
  const posY = coordinates[1] - 9;
  const [oldPosX, setOldPosX] = React.useState(null);
  const [oldPosY, setOldPosY] = React.useState(null);

  const [orientationX, setOrientationX] = React.useState(1);
  const [orientationY, setOrientationY] = React.useState(1);

  React.useEffect(() => {
    if (!oldPosX) {
      setOldPosX(posX);
      setOldPosY(posY);
      return;
    }

    if (oldPosX >= posX) {
      setOrientationX(-1);
    } else {
      setOrientationX(1);
    }
    setOldPosX(posX);

    if (oldPosY >= posY) {
      setOrientationY(1);
    } else {
      setOrientationY(-1);
    }
    setOldPosY(posY);
  }, [posX, posY]);

  const shipStyles = {
    position: "absolute",
    left: `${posX}%`,
    top: `${posY}%`,
    height: `${size}px`,
    width: `${size}px`,
    transform: `scaleX(${orientationX}) scaleY(${orientationY}) rotate(20deg)`,
  };

  return <PlayerMarker src={playerIcon} style={shipStyles}></PlayerMarker>;
};

const PlayerMarker = styled.img`
  transition: left 5000ms, top 5000ms;
`;

export default PlayerIcon;
