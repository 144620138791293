import React from "react";
import styled from "styled-components";

import { COLORS } from "../../constants";

const ValueGraph = ({ resourceData }) => {
  const { priceMin, priceMax, priceHistory } = resourceData;

  //   console.log("min:", priceMin, "max:", priceMax);

  let graphMax = priceMin;
  let graphMin = priceMax;

  priceHistory.forEach((price) => {
    if (price > graphMax) {
      graphMax = price;
    }
    if (price < graphMin) {
      graphMin = price;
    }
  });

  graphMax = Math.ceil(graphMax + (graphMax / 100) * 10);
  graphMin = Math.ceil(graphMin - (graphMin / 100) * 5);

  return (
    <Wrapper>
      <Title>Price History</Title>

      <Graph>
        <Ribbon> </Ribbon>
        {priceHistory.map((price, index) => {
          const topBracket = graphMax - graphMin;
          const flatPrice = price - (graphMin - (graphMin / 100) * 5);

          //   console.log(price - (priceMin - (priceMin / 100) * 5));

          const posY = Math.floor((flatPrice / topBracket) * 100);
          //   console.log(price, posY);
          let dotStyles = {
            bottom: `${posY}%`,
            backgroundColor: "black",
            height: "4px",
            width: "4px",
          };
          if (index === 0) {
            dotStyles = {
              bottom: `${posY}%`,
              backgroundColor: `${COLORS.credits}`,
              height: "6px",
              width: "6px",
            };
          }
          return (
            <Ribbon key={`${price}+${index}`}>
              <PriceDot style={dotStyles} />
            </Ribbon>
          );
        })}

        <FirstRibbon>
          <PriceAxis>{graphMax}</PriceAxis>
          <PriceAxis>{graphMin}</PriceAxis>
        </FirstRibbon>
      </Graph>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const Graph = styled.div`
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  height: 300px;
  width: 660px;
  margin-bottom: 40px;
`;

const Title = styled.h4`
  margin-bottom: 20px;
  text-align: center;
  font-weight: 200;
`;

const Ribbon = styled.div`
  width: 40px;
  position: relative;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid black;
`;

const FirstRibbon = styled.div`
  width: 40px;
  position: relative;
  border-right: 1px solid rgba(0, 0, 0, 1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const PriceAxis = styled.p`
  position: relative;
  text-align: right;
  left: -1ch;
`;

const PriceDot = styled.div`
  margin-left: 50%;
  height: 4px;
  width: 4px;
  position: absolute;
  border-radius: 100%;
`;

export default ValueGraph;
