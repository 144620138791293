import React from "react";
import styled from "styled-components";

import Tooltip from "../Tooltip";
import PlanetInfo from "./PlanetInfo";
import ProgressBar from "../ProgressBar";
import { MiningContext } from "../MiningContext";

const PlanetIcon = ({
  playerHere,
  planetName,
  iconSrc,
  coordinates,
  setSelectedPlanet,
  isSelected,
  resources,
}) => {
  const { completed } = React.useContext(MiningContext);
  let tooltipLocation = "below";

  if (coordinates[1] > 70) {
    tooltipLocation = "above";
  }
  if (coordinates[0] >= 70) {
    tooltipLocation = "left";
  }

  return (
    <Wrapper
      style={{
        left: `${coordinates[0]}%`,
        top: `${coordinates[1]}%`,
      }}
    >
      <ClickableIcon
        src={iconSrc}
        onMouseDown={() => {
          if (isSelected) {
            setSelectedPlanet(null);
          } else {
            setSelectedPlanet(planetName);
          }
        }}
      />
      {isSelected && (
        <Tooltip
          position={tooltipLocation}
          dismiss={() => {
            setSelectedPlanet(null);
          }}
        >
          <PlanetInfo
            planetName={planetName}
            resources={resources}
            playerHere={playerHere}
            setSelectedPlanet={setSelectedPlanet}
          />
        </Tooltip>
      )}
      {playerHere && completed > 0 && (
        <ProgressHolder>
          <ProgressBar completed={completed} />
        </ProgressHolder>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: absolute;
`;

const ClickableIcon = styled.img`
  height: 92px;
  width: 102px;
  outline: none;
  z-index: 1;

  &:hover {
    cursor: pointer;
  }
`;

const ProgressHolder = styled.div`
  position: relative;
  width: 130px;
  top: 7px;
  right: 14px;
`;

export default PlanetIcon;
