import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserContext } from "./UserContext";
import useInterval from "../hooks/use-interval.hook";

import { savePlayerProgress } from "../Helpers/api-helpers";
import { addProgressFlag } from "../actions";

const GameManagement = () => {
  const dispatch = useDispatch();
  const { accessToken } = useSelector((state) => state.user);
  const {
    saveTick,
    setSaveTick,
    localSaveData,
    setLocalSaveData,
  } = React.useContext(UserContext);
  const { gameData } = useSelector((state) => state);
  const { id, flags, playerCash } = useSelector((state) => state.gameData);

  const [lastSave, setLastSave] = React.useState(null);

  React.useEffect(() => {
    if (gameData && !lastSave) {
      setLastSave(gameData);
      // savePlayerProgress(accessToken, gameData).then((res) =>
      //   console.log(res.message)
      // );
      //using local saves for better user expeerience at this point
      setLocalSaveData(gameData);

      return;
    }
  }, [gameData, lastSave]);

  React.useEffect(() => {
    if (!lastSave) {
      return;
    }
    if (gameData.hash === lastSave.hash) {
      // console.log(" should not save! same hash!");
      return;
    }
    setLastSave(gameData);
    setLocalSaveData(gameData);
    // savePlayerProgress(accessToken, gameData).then((res) =>
    //   console.log(res.message)
    // );
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [saveTick, id]);

  useInterval(() => {
    setSaveTick(!saveTick);
  }, 60000);

  React.useEffect(() => {
    if (!flags.canResearch) {
      if (playerCash > 50000) {
        dispatch(addProgressFlag("canResearch"));
        setLocalSaveData(gameData);
        // savePlayerProgress(accessToken, gameData);
      }
    }
  });

  return null;
};

export default GameManagement;
