import React from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import { sellRequest } from "../../Helpers/api-helpers";
import LoadingSpinner from "../LoadingSpinner";
import coinsSrc from "../../assets/icons/coins_gold.svg";
import { COLORS } from "../../constants";

import {
  requestMarketTransaction,
  receiveMarketTransaction,
  marketTransactionError,
} from "../../actions";

const SellArea = ({
  name,
  inputValue,
  setInputValue,
  itemInInventory,
  itemForSale,
  status,
}) => {
  const dispatch = useDispatch();
  const { accessToken } = useSelector((state) => state.user);
  const { gameData } = useSelector((state) => state);

  const saleRequest = (item, qty) => {
    dispatch(requestMarketTransaction());
    sellRequest(accessToken, gameData, item, qty).then((data) => {
      if (data.success) {
        dispatch(receiveMarketTransaction(data.gameData, data.message));
      } else {
        dispatch(marketTransactionError(data.message));
        console.log("error", data);
      }
    });
  };

  return (
    <Wrapper>
      <SellText>
        You have {itemInInventory.amount} {name} in your cargo.
      </SellText>

      <SaleInfo>
        <div>
          <SellText>
            Sell x{" "}
            <Amount
              onBlur={(ev) => {
                if (ev.target.value > itemInInventory.amount) {
                  setInputValue(itemInInventory.amount);
                }
              }}
              type="number"
              min="1"
              max={itemInInventory.amount}
              value={inputValue}
              onChange={(ev) => {
                setInputValue(ev.target.value);
              }}
            />
            <AddAllButton
              onClick={() => {
                setInputValue(itemInInventory.amount);
              }}
            >
              MAX
            </AddAllButton>
          </SellText>
        </div>
      </SaleInfo>

      <BuyButton
        disabled={
          itemInInventory.amount < inputValue || status === "awaiting-response"
        }
        onClick={() => {
          saleRequest(itemForSale.name, inputValue);
        }}
      >
        {status === "awaiting-response" ? (
          <LoadingSpinner size={20} />
        ) : (
          <SellText>
            Sell for <span>{itemForSale.priceHistory[0] * inputValue}</span>{" "}
            <Icon src={coinsSrc} />
          </SellText>
        )}
      </BuyButton>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 20px;
  width: 85%;
  margin: 0 auto;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid rgba(0, 0, 0, 0.45);
`;

const SaleInfo = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
`;

const SellText = styled.p`
  margin-top: -4px;
  & span {
    margin-left: 4px;
    color: ${COLORS.credits};
  }
`;

const Icon = styled.img`
  align-self: center;
  height: 28px;
  width: 32px;
  position: relative;
  top: 5px;
`;

const AddAllButton = styled.button`
  margin-left: 10px;
  border: 2px transparent;
  border-radius: 300px;
  padding: 10px 15px;
  background-color: rgba(0, 0, 0, 0.12);
  transition: all 100ms;
  outline: none;

  &:hover {
    transform: scale(1.1);
    background-color: rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
`;

const BuyButton = styled.button`
  height: 50px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 300px;
  border: 2px transparent;
  padding: 0 10px 8px;
  background-color: rgba(0, 0, 0, 0.12);
  transition: all 100ms;
  width: 200px;
  outline: none;

  &:hover {
    &:enabled {
      transform: scale(1.1);
    }

    background-color: rgba(0, 0, 0, 0.12);
    cursor: pointer;
  }

  & span {
    color: ${COLORS.credits};
  }
`;

const Amount = styled.input`
  line-height: 18px;
  width: 5.5ch;
`;

export default SellArea;
