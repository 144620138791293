import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { UserContext } from "../UserContext";

import playerIcon from "../../assets/icons/rocket.svg";
import { SHIPS_DATA } from "../../data/gameData";

import {
  purchaseFuelUnit,
  upgradeMaxCargo,
  upgradeMaxFuel,
  addProgressFlag,
} from "../../actions";

import Upgrade from "./Upgrade";
import PurchaseItem from "./PurchaseItem";

const Ship = () => {
  const dispatch = useDispatch();
  const { triggerSaveProgress } = React.useContext(UserContext);
  const { playerCash, playerFuel, shipData, flags } = useSelector(
    (state) => state.gameData
  );

  const ship = SHIPS_DATA.find((item) => item.id === shipData.currentShip);

  const maxFuel = ship.baseFuel + ship.fuelStep * shipData.fuelLevel;
  const maxFuelCost = Math.floor(
    ship.fuelCost * ship.growth ** shipData.fuelLevel
  );
  const maxCargoCost = Math.floor(
    ship.cargoCost * ship.growth ** shipData.cargoLevel
  );

  return (
    <Wrapper>
      <Title>{ship.name}</Title>
      <ShipActions>
        <ForSale>
          <SubTitle>Consumables</SubTitle>
          <PurchaseItem
            amount={5}
            cost={200}
            canBuy={playerCash >= 200}
            maxed={playerFuel + 5 > maxFuel}
            type={"Fuel Units"}
            onClick={() => {
              dispatch(purchaseFuelUnit(5, 200));
            }}
          />
        </ForSale>
        <ImageBox>
          <ShipVisuals src={playerIcon} />
        </ImageBox>
        <Equipment>
          <SubTitle>Ship Upgrades</SubTitle>
          <Upgrade
            step={ship.fuelStep}
            cost={maxFuelCost}
            canBuy={playerCash >= maxFuelCost}
            type={"Max Fuel"}
            maxed={shipData.fuelLevel === ship.maxFuelLevel}
            onClick={() => {
              dispatch(upgradeMaxFuel(ship.fuelStep, maxFuelCost));
            }}
          />
          <Upgrade
            step={ship.cargoStep}
            cost={maxCargoCost}
            canBuy={playerCash >= maxCargoCost}
            type={"Max Cargo"}
            maxed={shipData.cargoLevel === ship.maxCargoLevel}
            onClick={() => {
              dispatch(upgradeMaxCargo(10, maxCargoCost));
            }}
          />
          {flags.canResearch && !flags.aiUnlocked && (
            <Upgrade
              step={null}
              cost={100000}
              canBuy={playerCash >= 100000}
              type={"AI Research"}
              onClick={() => {
                dispatch(addProgressFlag("aiUnlocked"));
              }}
            />
          )}
        </Equipment>
      </ShipActions>
    </Wrapper>
  );
};
const Wrapper = styled.section`
  padding: 15px;
  grid-column: 1;
  grid-row: 1;
  border-bottom: 1px solid black;
`;

const Title = styled.h2`
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.8rem;
`;

const SubTitle = styled.p`
  text-align: center;
`;

const ShipActions = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ImageBox = styled.div`
  position: relative;
  height: 175px;
  width: 270px;
  overflow: hidden;
`;
const ShipVisuals = styled.img`
  position: absolute;
  top: -110px;
  left: -45px;
  height: 350px;
  width: 350px;
  transform: rotate(25deg);
`;

const ForSale = styled.ul``;

const Equipment = styled.div`
  justify-content: center;
`;

export default Ship;
