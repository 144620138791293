import React from "react";
import styled from "styled-components";

import gitHubSrc from "../assets/images/GitHub-Mark-120px-plus.png";
import linkedInSrc from "../assets/images/linkedIn.png";
import twitterSrc from "../assets/images/twitter.png";

import Popup from "./Popup";

const AboutMe = ({ hidePopup, showPopup }) => {
  if (!showPopup) {
    return null;
  }

  return (
    <Popup dismiss={hidePopup}>
      <AreaTitle> About me </AreaTitle>
      <Message>
        Hi! My name is Etienne Giroux, and I hope you are enjoying my little
        game!
      </Message>
      <Message>
        I made this as a final project in my full-stack web development bootcamp
        in October and November 2020.
      </Message>

      <Message>
        I've been a game designer most of my professionnal life, so I decided to
        make my own game from scratch.
      </Message>
      <Message>
        You can learn more about me using the following social media links:
      </Message>
      <SocialLinks>
        <SocialLink target="_blank" href="https://github.com/eggiroux">
          <Icon src={gitHubSrc} />
        </SocialLink>
        <SocialLink target="_blank" href="https://www.linkedin.com/in/egiroux/">
          <Icon src={linkedInSrc} />
        </SocialLink>
        <SocialLink target="_blank" href="https://twitter.com/wargarine">
          <Icon src={twitterSrc} />
        </SocialLink>
      </SocialLinks>
    </Popup>
  );
};

const AreaTitle = styled.h3`
  text-align: center;
  margin-bottom: 15px;
`;

const Message = styled.p`
  width: 60ch;
  margin-bottom: 20px;
`;

const SocialLink = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Icon = styled.img`
  height: 32px;
  width: 32px;
`;

const SocialLinks = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 15px 0 30px;
`;

export default AboutMe;
