import React from "react";
import styled, { keyframes } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import useInterval from "../hooks/use-interval.hook";
import useKeydown from "../hooks/use-keydown.hook";

import { enterUsername, narrativeMessage } from "../actions";

const introMessages = [
  {
    content: "Scanning life form.",
    nextDelay: 2000,
  },
  {
    content: "Welcome, Alive Thing.",
    nextDelay: 2000,
  },
  {
    content: "Your life form's key data points are not recognized.",
    nextDelay: 3000,
  },
  {
    content:
      "Identify yourself if you want to begin monetary-gain-focused activities.",
    nextDelay: null,
  },
];

const WelcomeDiv = () => {
  const dispatch = useDispatch();
  const messages = useSelector((state) => state.logs.messages);
  const messageRef = React.useRef();
  const inputRef = React.useRef();
  const [input, setInput] = React.useState("");
  const [delay, setDelay] = React.useState(1800);
  const [messageIndex, setMessageIndex] = React.useState(0);
  const [animFinished, setAnimFinished] = React.useState(false);

  React.useEffect(() => {
    if (messageIndex === 4) {
      inputRef.current.focus();
    }
  }, [messages, messageIndex]);

  useKeydown("Enter", () => {
    if (messageIndex === 4 && input.length >= 2) {
      dispatch(narrativeMessage("Input Accepted."));
      window.setTimeout(() => {
        dispatch(
          narrativeMessage(
            `Callsign { ${input} } assigned to Alive Thing held in current memory. Please Proceed.`
          )
        );
      }, 1500);

      setMessageIndex(messageIndex + 1);
      window.setTimeout(() => {
        dispatch(enterUsername(input));
      }, 5000);
    }
  });

  useInterval(() => {
    setAnimFinished(true);
    if (messageIndex === 0) {
      setAnimFinished(true);
      setMessageIndex(messageIndex + 1);
      return;
    }
    dispatch(narrativeMessage(introMessages[messageIndex].content));
    setDelay(introMessages[messageIndex].nextDelay);
    setMessageIndex(messageIndex + 1);
  }, delay);

  return (
    <Wrapper>
      <InfoDiv>
        <Title style={{ visibility: `${animFinished ? "visible" : "hidden"}` }}>
          Communication Log
        </Title>
        {animFinished && (
          <Messages ref={messageRef}>
            {messages.map((message, index) => {
              return <Line key={message + index}>{message}</Line>;
            })}

            {messageIndex === 4 && (
              <Line>
                <UnstyledTextInput
                  ref={inputRef}
                  value={input}
                  onChange={(ev) => {
                    setInput(ev.target.value);
                  }}
                  onBlur={() => {
                    inputRef.current.focus();
                  }}
                />
              </Line>
            )}
          </Messages>
        )}
      </InfoDiv>
    </Wrapper>
  );
};

const appear = keyframes`
    0% {
        height: 1px;
        width: 1px

    }

    60% {
        height: 1px;
        width: 55%;

    }
    100% {
      height: 300px;
      width: 55%;
    }
`;

const Wrapper = styled.div`
  width: 100vw;
  height: 40vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InfoDiv = styled.div`
  width: 55%;
  height: 300px;
  border: 1px solid black;
  background-color: black;
  color: #336699;
  border-radius: 5px;
  animation: ${appear} 1500ms;
`;

const Title = styled.h3`
  margin: 5px;
`;

const Line = styled.p`
  margin-left: 5px;
  position: relative;
  font-style: italic;
  scroll-snap-align: end;

  &::before {
    content: "> ";
    color: #336699;
  }
`;

const Messages = styled.div`
  height: 88%;
  overflow-y: auto;
`;

const UnstyledTextInput = styled.input`
  border: none;
  outline: none;
  background-color: black;
  color: #336699;
  font-size: 16px;
  width: 40ch;

  &::placeholder {
    color: #336699;
    font-style: italic;
  }
`;

export default WelcomeDiv;
