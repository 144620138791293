import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";

import { beginTransactionProcess } from "../../actions";
import { RESOURCE_DATA } from "../../data/gameData";

import InventoryItem from "./InventoryItem";
import cargoSrc from "../../assets/icons/cargo.svg";

import { calcTotalLoad, getMaxCargoSize } from "../../Helpers/Utils";

const Cargo = () => {
  const dispatch = useDispatch();
  const { inventory, shipData } = useSelector((state) => state.gameData);

  return (
    <Wrapper>
      <Title>
        Cargo <Icon src={cargoSrc} />
        {`(${calcTotalLoad(inventory)}/${getMaxCargoSize(shipData)})`}{" "}
      </Title>
      <Inventory>
        {inventory.map((item) => {
          return (
            <li key={item.name}>
              <InventoryItem
                name={item.name}
                iconSrc={RESOURCE_DATA[item.name].src}
                amount={item.amount}
                onClick={() => {
                  dispatch(beginTransactionProcess("sale", item.name));
                }}
              />
            </li>
          );
        })}
        <InventoryItem empty={true} />
      </Inventory>
    </Wrapper>
  );
};
const Wrapper = styled.section`
  padding: 15px;
  grid-column: 1;
  grid-row: 2;
`;

const Title = styled.h2`
  text-align: center;
  font-size: 1.8rem;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Icon = styled.img`
  height: 52px;
  width: 52px;
  margin: 0px 10px;
`;

const Inventory = styled.ul`
  display: grid;
  width: 90%;
  overflow-y: auto;
  grid-template-columns: repeat(auto-fill, minmax(75px, 1fr));
  grid-gap: 24px;
`;

export default Cargo;
