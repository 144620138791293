import React from "react";
import styled from "styled-components/macro";
import {
  GrCaretUp,
  GrCaretDown,
  GrCaretPrevious,
  GrCaretNext,
} from "react-icons/gr";

const Tooltip = ({ children, position = "below", dismiss }) => {
  let positionStyles = { top: "110%", left: "-45%" };

  if (position === "above") {
    positionStyles = { top: "-135%", left: "-45%" };
  } else if (position === "left") {
    positionStyles = { left: "-200%", bottom: "-25%" };
  } else if (position === "right") {
    positionStyles = { right: "-200%", bottom: "-15%" };
  }

  return (
    <Wrapper style={positionStyles}>
      <ClickCatcher onMouseDown={dismiss} />
      {position === "below" && (
        <UpArrow>
          <GrCaretUp />
        </UpArrow>
      )}
      {children}
      {position === "above" && (
        <DownArrow>
          <GrCaretDown />
        </DownArrow>
      )}
      {position === "left" && (
        <RightArrow>
          <GrCaretNext />
        </RightArrow>
      )}

      {position === "right" && (
        <LeftArrow>
          <GrCaretPrevious />
        </LeftArrow>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  z-index: 4;
  background-color: white;
  border-radius: 10px;
  border: 1px black solid;
  opacity: 1;
  position: absolute;
  height: 120px;
  width: 200px;
`;

const UpArrow = styled.div`
  position: absolute;
  top: -15px;
  left: 45%;
`;

const DownArrow = styled.div`
  position: absolute;
  bottom: -25px;
  left: 45%;
`;
const LeftArrow = styled.div`
  position: absolute;
  bottom: 45%;
  left: -15px;
`;

const RightArrow = styled.div`
  position: absolute;
  bottom: 45%;
  right: -15px;
`;

const ClickCatcher = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0);
`;

export default Tooltip;
