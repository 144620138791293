import { ip } from "../constants";

export function fetchPlayerProfile(token) {
  const options = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  return fetch(`${ip}/users`, options).then((res) => res.json());
}

export function savePlayerProgress(token, gameData) {
  const options = {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      // Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      gameData,
    }),
  };

  return fetch(`${ip}/users`, options).then((res) => res.json());
}

export function travelToNewSector(gameData) {
  const options = {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      // Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      gameData,
    }),
  };

  return fetch(`${ip}/newSector`, options).then((res) => res.json());
}

export function sellRequest(token, gameData, item, qty) {
  const options = {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      // Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      clientGameData: gameData,
      item: item,
      qty: qty,
    }),
  };

  return fetch(`${ip}/market/sell`, options).then((res) => res.json());
}

export function buyRequest(token, gameData, item, qty) {
  const options = {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      // Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      clientGameData: gameData,
      item: item,
      qty: qty,
    }),
  };

  return fetch(`${ip}/market/purchase`, options).then((res) => res.json());
}

export function fetchMarketData() {
  return fetch(`${ip}/market/prices/`).then((res) => res.json());
}

export function userCreateAccount(email, password) {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email,
      password,
    }),
  };

  return fetch(`${ip}/users`, options).then((res) => res.json());
}

export function userLogIn(email, password) {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email,
      password,
    }),
  };
  return fetch(`${ip}/users/login`, options).then((res) => res.json());
}

export function userLogOut(token) {
  const options = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      token,
    }),
  };

  return fetch(`${ip}/logout`, options);
}

export function refreshAccessToken(token) {
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      token,
    }),
  };
  return fetch(`${ip}/token`, options).then((res) => res.json());
}
