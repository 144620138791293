import React from "react";
import styled from "styled-components";

import { COLORS } from "../../constants";
import creditsSrc from "../../assets/icons/coins_gold.svg";
import fuelSrc from "../../assets/icons/atom_blue.svg";

const PurchaseItem = ({ amount, cost, canBuy, type, onClick, maxed }) => {
  if (maxed) {
    return (
      <Wrapper>
        <Purchase>
          <Name>
            <Icon src={fuelSrc} />
          </Name>
          <p>Full!</p>
        </Purchase>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Purchase disabled={!canBuy} onClick={onClick}>
        <Icon src={fuelSrc} />
        <TextArea>
          <Name>{`+ ${amount}`} energy</Name>
          <Cost>
            {cost} <Icon src={creditsSrc} />
          </Cost>
        </TextArea>
      </Purchase>
    </Wrapper>
  );
};

const Wrapper = styled.li`
  padding: 5px;
  font-size: 16px;
  list-style: none;
`;

const TextArea = styled.div`
  padding-top: 5px;
`;

const Purchase = styled.button`
  padding: 5px 18px;
  font-size: 16px;
  width: 160px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 200px;
  border: 2px transparent;

  transition: all 100ms;
  &:hover {
    &:enabled {
      transform: scale(1.1);
    }
    background-color: rgba(0, 0, 0, 0.12);
    cursor: pointer;
  }

  &:disabled {
    & p {
      color: red;
    }
  }
`;

const Name = styled.h4`
  color: black;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Cost = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: ${COLORS.credits};
`;

const Icon = styled.img`
  height: 48px;
  width: 48px;
`;

export default PurchaseItem;
