import React from "react";
import styled from "styled-components";

const Grid = () => {
  let cells = [];

  for (let i = 0; i < 64; i++) {
    cells.push(i);
  }

  return (
    <Wrapper>
      {cells.map((cell) => {
        return <Cell key={cell} />;
      })}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  width: 120%;
  left: -5%;
  top: -15%;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: center;
  opacity: 0.65;
`;

const Cell = styled.div`
  margin: 0;
  border: dashed lightblue 1px;
  width: 8.3vw;
  padding-top: 8.3vw;
`;

export default Grid;
