import React from "react";
import styled from "styled-components";
import GlobalStyles from "./Globalstyles";
import { useDispatch, useSelector } from "react-redux";

import LoadingSpinner from "./LoadingSpinner";
import { UserContext } from "./UserContext";
import MiningProvider from "./MiningContext";

import { fetchPlayerProfile, refreshAccessToken } from "../Helpers/api-helpers";
import {
  requestGameData,
  receiveGameData,
  requestGameDataFailure,
  receiveUserInfo,
} from "../actions";

import Header from "./Header";
import GameManagement from "./GameManagement";
import WelcomeDiv from "./WelcomeDiv";
import Ship from "./Ship";
import Cargo from "./Cargo";
import PlayerStats from "./PlayerStats";
import InfoConsole from "./InfoConsole";
import Market from "./Market";
import Popups from "./Popups";
import GalaxyMap from "./GalaxyMap";
import PlanetView from "./PlanetView";

function App() {
  const { accessToken } = useSelector((state) => state.user);
  const { status, planetView, id } = useSelector((state) => state.gameData);
  const { refreshToken, setRefreshToken, localSaveData } = React.useContext(
    UserContext
  );

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (localSaveData.id === "newPlayer") {
      if (!accessToken) {
        if (!refreshToken) {
          //if no access or refresh token, use local storage
          //console.log(localSaveData);
          dispatch(receiveGameData(localSaveData, ""));

          return;
        }
        //if no access token, but refresh token, refresh the token
        refreshAccessToken(refreshToken)
          .then((res) => {
            console.log("receive new Token");
            dispatch(receiveUserInfo(res.accessToken));
          })
          .catch((err) => {
            dispatch(
              receiveGameData(
                localSaveData,
                `Welcome back, ${localSaveData.id}. Come and trade henceforth!`
              )
            );
            dispatch(receiveUserInfo(null));
            setRefreshToken(null);
          });
        return;
      }

      //if yes access token, use it to get game data
      dispatch(requestGameData());
      fetchPlayerProfile(accessToken)
        .then((res) => {
          console.log("receive data from server");
          dispatch(receiveGameData(res.gameData, res.message));
          dispatch(receiveUserInfo(null));
          setRefreshToken(null);
        })
        .catch((error) => {
          dispatch(requestGameDataFailure(error));
        });
    }
  }, [accessToken]);

  if (status === "loading") {
    return (
      <>
        <GlobalStyles />
        <Header />
        <LoadingSpinner />
        {/* login or create an account landing page */}
      </>
    );
  }

  if (id === "newPlayer") {
    return (
      <>
        <GlobalStyles />
        <TopArea>
          <Header />
        </TopArea>
        <WelcomeDiv />
      </>
    );
  }

  return (
    <Wrapper>
      <GlobalStyles />
      <GameManagement />
      <Popups />
      <TopArea>
        <Header />
        <PlayerStats />
        <Market />
      </TopArea>
      <Game>
        <Ship />
        <Cargo />
        <InfoConsole />
        <SpaceView>
          <MiningProvider>
            {/* <Shops /> */}
            {planetView ? <PlanetView /> : <GalaxyMap />}
          </MiningProvider>
        </SpaceView>
      </Game>
    </Wrapper>
  );
}
const Wrapper = styled.main`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const TopArea = styled.div`
  height: 160px;
  z-index: 4;
`;

const Game = styled.div`
  height: calc(100vh - 160px);
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 192px;
`;

const SpaceView = styled.div`
  flex: 1;
  grid-column: 2 / span 1;
  grid-row: 1 / end;
  border-left: 1px solid black;

  overflow: hidden;
`;

export default App;
