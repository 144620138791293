import coffeeSrc from "../assets/icons/coffee2.svg";
import crystalSrc from "../assets/icons/crystal.svg";
import drugsSrc from "../assets/icons/pills.svg";
import gummySrc from "../assets/icons/gummy.svg";
import fuelSrc from "../assets/icons/water.svg";
import bulbSrc from "../assets/icons/onion.svg";
import saltSrc from "../assets/icons/salt.svg";
import sporesSrc from "../assets/icons/spores2.svg";
import metalSrc from "../assets/icons/metal.svg";

import planet1Src from "../assets/icons/planet1.svg";
import planet2Src from "../assets/icons/planet2.svg";
import planet3Src from "../assets/icons/planet3.svg";
import planet4Src from "../assets/icons/planet4.svg";
import planet5Src from "../assets/icons/planet5.svg";
import planet6Src from "../assets/icons/planet6.svg";
import planet7Src from "../assets/icons/planet7.svg";

export const RESOURCE_DATA = {
  coffee: {
    name: "Coffee",
    src: coffeeSrc,
  },
  crystal: {
    name: "Crystal",
    src: crystalSrc,
  },
  drugs: {
    name: "Drugs",
    src: drugsSrc,
  },
  gummies: {
    name: "Gummies",
    src: gummySrc,
  },
  "raw fuel": {
    name: "Water",
    src: fuelSrc,
  },
  bulbs: {
    name: "Bulbs",
    src: bulbSrc,
  },
  salt: {
    name: "Salt",
    src: saltSrc,
  },
  spores: {
    name: "Spores",
    src: sporesSrc,
  },
  metal: {
    name: "Metal",
    src: metalSrc,
  },
};

export const PLANETS_DATA = {
  blue: {
    src: planet1Src,
  },
  forestgreen: {
    src: planet2Src,
  },
  darkcyan: {
    src: planet3Src,
  },
  deepskyblue: {
    src: planet4Src,
  },
  red: {
    src: planet5Src,
  },
  darkslategray: {
    src: planet6Src,
  },
  fuchsia: {
    src: planet7Src,
  },
};

export const SHIPS_DATA = [
  {
    id: 1,
    name: "Shoddy Shuttle",
    baseFuel: 50,
    maxFuelLevel: 5,
    fuelStep: 10,
    fuelCost: 2000,
    baseCargo: 10,
    maxCargoLevel: 10,
    cargoStep: 1,
    cargoCost: 10000,
    growth: 1.5,
  },
  {
    id: 2,
    name: "Mining Ship",
    baseFuel: 100,
    maxFuelLevel: 10,
    fuelStep: 10,
    fuelCost: 70000,
    baseCargo: 20,
    maxCargoLevel: 10,
    cargoStep: 1,
    cargoCost: 100000,
    growth: 1.2,
  },
];

export const newPlayerData = {
  hash: "initial",
  id: "newPlayer",
  currentLocation: "unknown planet",
  planetView: false,
  currentSector: {
    "unknown planet": {
      name: "unknown planet",
      resources: {
        "raw fuel": 2,
        spores: 4,
        metal: 10,
      },
      coordinates: [45, 25],
      color: "blue",
    },
    "xurgon-bb": {
      name: "xurgon-bb",
      resources: {
        bulbs: 6,
        crystal: 5,
        salt: 2,
      },
      coordinates: [15, 50],
      color: "darkslategray",
    },
  },

  playerCash: 20,
  playerFuel: 15,
  playerDebt: 5000,
  shipData: { currentShip: 1, fuelLevel: 0, cargoLevel: 0 },
  inventory: [
    {
      name: "metal",
      amount: 1,
      size: 1,
    },
  ],
  flags: {
    canResearch: false,
    aiUnlocked: false,
  },
};
