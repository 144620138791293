import produce from "immer";

const initialState = {
  status: "loading",
  error: "",
  marketData: null,
  lastUpdate: null,
};

export default function marketReducer(state = initialState, action) {
  // console.log(action);
  switch (action.type) {
    case "MARKET_DATA_REQUEST": {
      return produce(state, (draftState) => {
        draftState.status = "loading";
      });
    }
    case "MARKET_DATA_UPDATE": {
      return produce(state, (draftState) => {
        draftState.status = "updating";
      });
    }
    case "MARKET_DATA_RECEIVE": {
      return produce(state, (draftState) => {
        draftState.status = "idle";
        draftState.marketData = action.marketData;
        draftState.lastUpdate = action.lastUpdate;
      });
    }
    case "MARKET_DATA_FAILURE": {
      return produce(state, (draftState) => {
        draftState.status = "error";
        draftState.error = action.err;
      });
    }

    default:
      return state;
  }
}
