import produce from "immer";

const initialState = {
  messages: [],
};

export default function infoLogReducer(state = initialState, action) {
  switch (action.type) {
    case "NARRATIVE_MESSAGE": {
      return produce(state, (draftState) => {
        draftState.messages.push(action.message);
      });
    }

    case "MARKET_TRANSACTION_RECEIVE": {
      return produce(state, (draftState) => {
        draftState.messages.push(action.message);
      });
    }

    case "GAME_DATA_RECEIVE": {
      // console.log(action.gameData.id);
      if (action.gameData.id === "newPlayer") {
        return produce(state, (draftState) => {
          draftState.messages.push("Scanning life form.");
        });
      } else {
        return produce(state, (draftState) => {
          draftState.messages.push(action.message);
        });
      }
    }

    case "MARKET_DATA_FAILURE": {
      return produce(state, (draftState) => {
        draftState.messages.push(action.message);
      });
    }

    case "USER_INFO_FAILURE": {
      return produce(state, (draftState) => {
        draftState.messages.push(action.message);
      });
    }

    default:
      return state;
  }
}
