import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

import UnstyledButton from "../UnstyledButton";
import Momentum from "./Momentum";

const MarketWidget = ({ priceHistory, onClick, iconSrc }) => {
  const { status } = useSelector((state) => state.market);

  const trend = priceHistory[0] >= priceHistory[1] ? "up" : "down";

  return (
    <Wrapper onClick={onClick}>
      <Icon src={iconSrc} />
      {status === "updating" ? (
        <Price>--</Price>
      ) : (
        <Price>
          ${priceHistory[0]} {trend && <Momentum trend={trend} />}
        </Price>
      )}
    </Wrapper>
  );
};

const Wrapper = styled(UnstyledButton)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  outline: none;
  margin: 4px;
  border: 1px solid black;
  background-color: white;
  border-radius: 10px;

  height: 48px;
  width: 110px;

  & :hover {
    cursor: pointer;
  }
`;

const Icon = styled.img`
  height: 22px;
  width: 22px;
  background-color: rgb(0, 0, 0, 0);
`;

const Price = styled.span``;

export default MarketWidget;
