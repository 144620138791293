import { combineReducers } from "redux";

import user from "./user-reducer";
import market from "./market-reducer";
import transaction from "./transaction-reducer";
import logs from "./infoLog-reducer";
import gameData from "./gameData-reducer";

export default combineReducers({
  user,
  market,
  transaction,
  logs,
  gameData,
});
