import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";

import UnstyledButton from "../UnstyledButton";
import Momentum from "./Momentum";

import { beginTransactionProcess } from "../../actions";

const MarketItem = ({ id, displayName, priceHistory, isSelected, iconSrc }) => {
  const dispatch = useDispatch();

  const { status } = useSelector((state) => state.market);

  const trend = priceHistory[0] >= priceHistory[1] ? "up" : "down";

  return (
    <Wrapper
      onMouseDown={(ev) => {
        ev.stopPropagation();
        dispatch(beginTransactionProcess("purchase", id));
      }}
      style={{
        border: isSelected ? "2px solid black" : "1px solid black",
        background: isSelected ? "lightyellow" : "white",
      }}
    >
      <Icon src={iconSrc} />

      <Name>{displayName}</Name>
      {status === "updating" ? (
        <Price>--</Price>
      ) : (
        <Price>
          ${priceHistory[0]} {trend && <Momentum trend={trend} />}
        </Price>
      )}
    </Wrapper>
  );
};

const Wrapper = styled(UnstyledButton)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  outline: none;
  margin: 4px;
  border: 1px solid black;
  border-radius: 10px;
  height: 125px;
  width: 110px;
  z-index: 2;

  & :hover {
    cursor: pointer;
  }
`;

const Icon = styled.img`
  height: 44px;
  width: 44px;
  background-color: rgb(0, 0, 0, 0);
`;

const Name = styled.h4`
  font-size: 1.2em;
`;

const Price = styled.span``;

export default MarketItem;
