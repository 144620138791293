import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

import { UserContext } from "../UserContext";
import Popup from "../Popup";

import fuelSrc from "../../assets/icons/atom_blue.svg";
import { COLORS } from "../../constants";
import { hyperJumpSentence } from "../../Helpers/Utils";

const HyperSpacePopup = ({ hidePopup, showPopup, onClick }) => {
  const { playerFuel } = useSelector((state) => state.gameData);
  const { triggerSaveProgress } = React.useContext(UserContext);
  const [shouldTrySave, setShouldTrySave] = React.useState(true);

  React.useEffect(() => {
    if (showPopup && shouldTrySave) {
      triggerSaveProgress();
      setShouldTrySave(false);
    }
  }, [showPopup, triggerSaveProgress, setShouldTrySave]);

  if (!showPopup) {
    if (!shouldTrySave) {
      setShouldTrySave(true);
    }

    return null;
  }

  return (
    <Popup dismiss={hidePopup}>
      <AreaTitle> HyperSpace Jump </AreaTitle>
      <Message>Wanna Hyperspace this tin can to a new Sector?</Message>

      <Message>
        This is Space we're talking about. No way to know where we'll end up.
        We'll try to aim for a place with some planets. Fair?
      </Message>

      <Message>Where to?</Message>

      <Choices>
        <SubmitButton onClick={onClick} disabled={playerFuel < 60}>
          {hyperJumpSentence()}
          <Cost>
            60
            <Icon src={fuelSrc} />
          </Cost>
        </SubmitButton>

        <SubmitButton onClick={onClick} disabled={playerFuel < 60}>
          {hyperJumpSentence()}
          <Cost>
            60
            <Icon src={fuelSrc} />
          </Cost>
        </SubmitButton>
      </Choices>
    </Popup>
  );
};

const AreaTitle = styled.h3`
  text-align: center;
  margin-bottom: 15px;
`;

const Message = styled.p`
  width: 60ch;
  margin-bottom: 20px;
`;

const Choices = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const SubmitButton = styled.button`
  font-size: 18px;
  align-self: center;
  width: 45%;
  border-radius: 200px;
  border: 2px transparent;
  padding: 10px 20px;
  font-size: 18px;

  transition: all 100ms;

  &:hover {
    &:enabled {
      transform: scale(1.1);
    }

    background-color: rgba(0, 0, 0, 0.12);
    cursor: pointer;
  }

  &:disabled {
    color: black;
    & p  {
      color: red;
    }
  }
`;

const Cost = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: ${COLORS.fuel};
`;

const Icon = styled.img`
  height: 32px;
  width: 32px;
`;

export default HyperSpacePopup;
