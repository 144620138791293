import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

import { calcNetworth } from "../Helpers/Utils";
import coinsSrc from "../assets/icons/coins_gold.svg";
import energySrc from "../assets/icons/atom_blue.svg";
import { SHIPS_DATA } from "../data/gameData";
import { COLORS } from "../constants";

const PlayerStats = () => {
  const { playerCash, shipData, playerFuel } = useSelector(
    (state) => state.gameData
  );
  const { marketData } = useSelector((state) => state.market);

  const ship = SHIPS_DATA.find((item) => item.id === shipData.currentShip);
  const maxFuel = ship.baseFuel + ship.fuelStep * shipData.fuelLevel;

  return (
    <StyledSection>
      <Credits>
        <CreditsIcon src={coinsSrc} />
        <span>{playerCash}</span>
      </Credits>
      <Fuel>
        <FuelIcon src={energySrc} />
        <span>
          {playerFuel}/{maxFuel}
        </span>
      </Fuel>
      {/* to be in the profile instead?  {marketData && (
        <Networth>
          Total Net Worth: <span>{calcNetworth(gameData, marketData)}</span>
        </Networth>
      )} */}
    </StyledSection>
  );
};

const StyledSection = styled.div`
  height: 32px;
  width: 100%;
  border-bottom: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: start;
`;

const Credits = styled.div`
  margin-left: 10px;
  display: flex;
  align-items: center;
  & span {
    color: ${COLORS.credits};
  }
`;

const CreditsIcon = styled.img`
  height: 40px;
  width: 40px;
`;

const FuelIcon = styled.img`
  height: 32px;
  width: 32px;
`;

const Fuel = styled.div`
  margin-left: 24px;
  display: flex;
  align-items: center;
  & span {
    color: ${COLORS.fuel};
  }
`;

const Networth = styled.div`
  margin-top: 10px;
  & span {
    color: mediumaquamarine;
  }
`;

export default PlayerStats;
