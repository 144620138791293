import React from "react";
import styled, { keyframes } from "styled-components";
import { useSelector } from "react-redux";

const InfoConsole = () => {
  const messages = useSelector((state) => state.logs.messages);
  const messageRef = React.useRef();

  React.useEffect(() => {
    messageRef.current.scrollTop = messageRef.current.scrollHeight;
  }, [messages]);

  return (
    <Wrapper>
      <Title>Communication Log</Title>
      {messages && (
        <Messages ref={messageRef}>
          {messages.map((message, index) => {
            return <Line key={message + index}>{message}</Line>;
          })}
          <Line>
            <Prompt>_</Prompt>
          </Line>
        </Messages>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  border: 1px solid black;
  padding: 5px;
  border-radius: 5px;
  grid-column: 1;
  grid-row: 3;
`;

const Title = styled.h2`
  margin-bottom: 5px;
`;

const Line = styled.p`
  font-style: italic;
  scroll-snap-align: end;

  &::before {
    content: "> ";
  }
`;

const blink = keyframes`
    to {
        visibility: hidden;

    }
`;
const Messages = styled.div`
  height: 88%;
  overflow-y: auto;
`;

const Prompt = styled.span`
  animation: ${blink} 1s steps(3, start) infinite;
`;
export default InfoConsole;
