import React from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import { calcTotalLoad, getMaxCargoSize } from "../../Helpers/Utils";
import { COLORS } from "../../constants";
import { buyRequest } from "../../Helpers/api-helpers";
import coinsSrc from "../../assets/icons/coins_gold.svg";
import LoadingSpinner from "../LoadingSpinner";

import {
  requestMarketTransaction,
  receiveMarketTransaction,
  marketTransactionError,
} from "../../actions";

const PurchaseArea = ({
  name,
  inputValue,
  setInputValue,
  itemForSale,
  status,
}) => {
  const dispatch = useDispatch();
  const { accessToken } = useSelector((state) => state.user);
  const { gameData } = useSelector((state) => state);
  const { playerCash, inventory, shipData } = useSelector(
    (state) => state.gameData
  );

  const purchaseRequest = (item, qty) => {
    dispatch(requestMarketTransaction());
    buyRequest(accessToken, gameData, item, qty).then((data) => {
      if (data.success) {
        dispatch(receiveMarketTransaction(data.gameData, data.message));
      } else {
        console.log("error", data);
        dispatch(marketTransactionError(data.message));
        console.log(data.message);
      }
    });
  };
  React.useEffect(() => {
    if (maxAmountPrice === 0) {
      setInputValue(0);
    }
  }, [inputValue]);

  const maxAmountCargo = getMaxCargoSize(shipData) - calcTotalLoad(inventory);
  const maxAmountPrice = Math.floor(playerCash / itemForSale.priceHistory[0]);
  const totalPrice = itemForSale.priceHistory[0] * inputValue;
  const canPurchase = totalPrice <= playerCash;

  // console.log("maxPrice:", maxAmountPrice, "maxCargo: ", maxAmountCargo);
  return (
    <Wrapper>
      <PurchaseText>
        You have {maxAmountCargo} free units in your cargo.
      </PurchaseText>
      <PurchaseText style={{ marginTop: "8px", marginBottom: "4px" }}>
        You {maxAmountPrice >= maxAmountCargo ? "could" : "can"} afford to buy{" "}
        {maxAmountPrice}{" "}
        {maxAmountPrice >= maxAmountCargo ? "if you had more space." : "."}
      </PurchaseText>

      <SaleInfo>
        <PurchaseText>
          Purchase x{" "}
          <Amount
            type="number"
            min="0"
            max={getMaxCargoSize(shipData) - calcTotalLoad(inventory)}
            value={inputValue}
            onChange={(ev) => {
              setInputValue(ev.target.value);
            }}
          />
          <AddAllButton
            onClick={() => {
              if (maxAmountCargo >= maxAmountPrice) {
                setInputValue(maxAmountPrice);
              } else {
                setInputValue(maxAmountCargo);
              }
            }}
          >
            MAX
          </AddAllButton>
        </PurchaseText>
      </SaleInfo>
      <BuyButton
        disabled={
          inputValue === "0" || !canPurchase || status === "awaiting-response"
        }
        onClick={() => {
          purchaseRequest(itemForSale.name, inputValue);
        }}
      >
        {status === "awaiting-response" ? (
          <LoadingSpinner size={20} />
        ) : (
          <PurchaseText>
            Get for <span>{itemForSale.priceHistory[0] * inputValue}</span>{" "}
            <Icon src={coinsSrc} />
          </PurchaseText>
        )}
      </BuyButton>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 20px;
  width: 85%;
  margin: 0 auto;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid rgba(0, 0, 0, 0.45);
`;

const SaleInfo = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PurchaseText = styled.p`
  & span {
    margin-left: 4px;
    color: ${COLORS.credits};
  }
`;

const Icon = styled.img`
  align-self: center;
  height: 28px;
  width: 32px;
  position: relative;
  top: 5px;
`;

const AddAllButton = styled.button`
  margin-left: 10px;
  border: 2px transparent;
  border-radius: 300px;
  padding: 10px 15px;
  background-color: rgba(0, 0, 0, 0.12);
  transition: all 100ms;
  outline: none;

  &:hover {
    transform: scale(1.1);
    background-color: rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
`;

const BuyButton = styled.button`
  height: 50px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 300px;
  border: 2px transparent;
  padding: 0 10px 8px;
  background-color: rgba(0, 0, 0, 0.12);
  transition: all 100ms;
  width: 200px;
  outline: none;

  &:hover {
    &:enabled {
      transform: scale(1.1);
    }

    background-color: rgba(0, 0, 0, 0.12);
    cursor: pointer;
  }

  & span {
    color: ${COLORS.credits};
  }
`;

const Amount = styled.input`
  line-height: 18px;
  width: 5.5ch;
`;

export default PurchaseArea;
