import React from "react";
import styled from "styled-components";

import { useSelector, useDispatch } from "react-redux";

import fuelSrc from "../../assets/icons/atom_blue.svg";
import { COLORS } from "../../constants";
import { RESOURCE_DATA } from "../../data/gameData";

import { planetTravel } from "../../actions";
import { estimateAmount } from "../../Helpers/Utils";
import { userZoomIn } from "../../actions";

const PlanetInfo = ({
  planetName,
  resources,
  setSelectedPlanet,
  playerHere,
}) => {
  const dispatch = useDispatch();

  const { playerFuel } = useSelector((state) => state.gameData);

  const [isTraveling, setIsTraveling] = React.useState(false);

  const resourceArray = Object.keys(resources);

  const travelCost = 20;

  const travelHere = () => {
    if (playerFuel >= travelCost) {
      setIsTraveling(true);
      dispatch(planetTravel(planetName, travelCost));
      setIsTraveling(false);
      setSelectedPlanet(null);
    }
  };

  return (
    <Wrapper>
      <Title>{planetName}</Title>

      {resourceArray.length === 0 && "Empty!"}

      {isTraveling ? (
        <p style={{ textAlign: "center" }}>Traveling!</p>
      ) : (
        resourceArray.map((item) => {
          return (
            <div key={item}>
              {RESOURCE_DATA[item].name}
              {playerHere
                ? ` x${resources[item]}`
                : ` (${estimateAmount(resources[item])})`}
            </div>
          );
        })
      )}
      {playerHere ? (
        <Button
          onClick={(ev) => {
            ev.stopPropagation();
            dispatch(userZoomIn(true));
          }}
        >
          Zoom In
        </Button>
      ) : (
        <Button
          disabled={playerFuel < travelCost}
          onClick={(ev) => {
            ev.stopPropagation();
            travelHere();
          }}
        >
          Travel There (<span>{travelCost}</span>
          <Icon src={fuelSrc} />)
        </Button>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  height: 100%;
`;

const Title = styled.h4`
  text-align: center;
`;

const Icon = styled.img`
  height: 24px;
  width: 24px;
`;
const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 200px;
  border: 2px transparent;

  transition: all 100ms;

  & span {
    color: ${COLORS.fuel};
  }

  &:hover {
    &:enabled {
      transform: scale(1.1);
    }
    background-color: rgba(0, 0, 0, 0.12);
    cursor: pointer;
  }

  &:disabled {
    & span {
      color: red;
    }
  }
`;

export default PlanetInfo;
