import produce from "immer";

const initialState = {
  status: "idle",
  type: "",
  selectedResource: null,
  error: "",
};

export default function transactionReducer(state = initialState, action) {
  // console.log(action);
  switch (action.type) {
    case "BEGIN_TRANSACTION_PROCESS": {
      return produce(state, (draftState) => {
        draftState.status = "resource-selected";
        draftState.type = action.transactionType;
        draftState.selectedResource = action.selectedResource;
      });
    }
    case "CANCEL_TRANSACTION_PROCESS": {
      return produce(state, (draftState) => {
        draftState.status = "idle";
        draftState.selectedResource = null;
        draftState.priceEach = null;
      });
    }
    case "MARKET_TRANSACTION_REQUEST": {
      return produce(state, (draftState) => {
        draftState.status = "awaiting-response";
      });
    }
    case "MARKET_TRANSACTION_RECEIVE": {
      return produce(state, (draftState) => {
        draftState.status = "idle";
      });
    }
    case "MARKET_TRANSACTION_FAILURE": {
      return produce(state, (draftState) => {
        draftState.status = "error";
        draftState.error = action.err;
      });
    }

    default:
      return state;
  }
}
