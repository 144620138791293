import React from "react";
import { useDispatch } from "react-redux";

import { userLogOut } from "../Helpers/api-helpers";
import usePersistedState from "../hooks/use-persistedState.hook";
import { userSignOut } from "../actions";
import { newPlayerData } from "../data/gameData";

import { receiveGameData } from "../actions";

export const UserContext = React.createContext(null);

const UserProvider = ({ children }) => {
  const [saveTick, setSaveTick] = React.useState(false);
  const [refreshToken, setRefreshToken] = usePersistedState(
    null,
    "refreshToken"
  );

  const [localSaveData, setLocalSaveData] = usePersistedState(
    newPlayerData,
    "llgGameData"
  );

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (localSaveData.id !== "newPlayer")
      dispatch(
        receiveGameData(
          localSaveData,
          `Welcome back, ${localSaveData.id}. Come and trade henceforth!`
        )
      );
  }, []);

  const handleSignOut = () => {
    userLogOut(refreshToken).then((res) => {
      console.log(res);
      dispatch(userSignOut());
    });
  };

  const triggerSaveProgress = () => {
    setSaveTick(!saveTick);
  };

  return (
    <UserContext.Provider
      value={{
        handleSignOut,
        triggerSaveProgress,
        saveTick,
        setSaveTick,
        setRefreshToken,
        refreshToken,
        localSaveData,
        setLocalSaveData,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
