import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { UserContext } from "./UserContext";
import UnstyledButton from "./UnstyledButton";

import AboutMe from "./AboutMe";

const SideMenu = ({ visible }) => {
  const { id } = useSelector((state) => state.gameData);

  const { handleSignOut } = React.useContext(UserContext);
  const [showAboutMe, setShowAboutMe] = React.useState(false);

  if (!visible) {
    return null;
  }
  return (
    <Wrapper>
      <MenuItem>user: {id}</MenuItem>
      <MenuButton disable="true">choose avatar (coming soon)</MenuButton>
      <MenuButton
        onClick={() => {
          setShowAboutMe(true);
        }}
      >
        about
      </MenuButton>
      <MenuButton onClick={handleSignOut}>Sign Out</MenuButton>
      <MenuItem style={{ borderBottom: "none" }}></MenuItem>
      <Version style={{ fontSize: "12px" }}>version: alpha 0.0.1</Version>
      <AboutMe
        hidePopup={() => {
          setShowAboutMe(false);
        }}
        showPopup={showAboutMe}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  top: 64px;
  right: 0;
  width: 250px;
  border: 1px solid black;
  border-radius: 3px;
  z-index: 10;
  background-color: white;
`;

const MenuButton = styled(UnstyledButton)`
  padding: 5px;
  width: 100%;
  height: 40px;
  text-align: left;
  border-bottom: 1px solid black;

  &:hover {
    background-color: lightgrey;
  }
`;

const MenuItem = styled.div`
  padding: 5px;
  width: 100%;
  height: 40px;
  text-align: left;
  display: flex;
  align-items: center;
  border-bottom: 1px solid black;
`;

const Version = styled.p`
  position: absolute;
  bottom: 2px;
  left: 5px;
`;

export default SideMenu;
