import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import hyperSpaceSrc from "../../assets/icons/hyperJump.svg";

import { PLANETS_DATA } from "../../data/gameData";

import { travelToSector } from "../../actions";
import { travelToNewSector } from "../../Helpers/api-helpers";

import Grid from "./Grid";
import PlanetIcon from "./PlanetIcon";
import PlayerIcon from "../PlayerIcon";
import HyperSpacePopup from "./HyperSpacePopup";
import UnstyledButton from "../UnstyledButton";

const GalaxyMap = () => {
  const dispatch = useDispatch();
  const { gameData } = useSelector((state) => state);
  const { currentSector, currentLocation } = useSelector(
    (state) => state.gameData
  );

  const [hyperTravel, setHyperTravel] = React.useState(false);
  const [showHyperPopup, setShowHyperPopup] = React.useState(false);

  const [selectedPlanet, setSelectedPlanet] = React.useState(null);

  const coordinates = currentSector[currentLocation].coordinates;
  const planetArray = Object.keys(currentSector);

  if (hyperTravel) {
    return (
      <Wrapper>
        <Grid />
        <Message>HYPERSPACIN'</Message>
      </Wrapper>
    );
  }
  return (
    <Wrapper>
      <Title>Sector </Title>
      <Grid />
      {planetArray.map((planet) => {
        return (
          <PlanetIcon
            key={planet}
            planetName={planet}
            resources={currentSector[planet].resources}
            coordinates={currentSector[planet].coordinates}
            iconSrc={PLANETS_DATA[currentSector[planet].color].src}
            playerHere={currentLocation === planet}
            setSelectedPlanet={setSelectedPlanet}
            isSelected={selectedPlanet === planet}
          />
        );
      })}
      <PlayerIcon size={84} coordinates={coordinates} />

      <HyperButton
        display={showHyperPopup ? "none" : "block"}
        onClick={() => {
          setShowHyperPopup(true);
        }}
      >
        <Icon src={hyperSpaceSrc} />
      </HyperButton>
      <HyperSpacePopup
        hidePopup={() => {
          setShowHyperPopup(false);
        }}
        showPopup={showHyperPopup}
        onClick={() => {
          setHyperTravel(true);
          travelToNewSector(gameData).then((json) => {
            console.log(json);
            dispatch(travelToSector(json.gameData, json.message));
            setHyperTravel(false);
            setShowHyperPopup(false);
          });
        }}
      >
        Hyper Space Jump
      </HyperSpacePopup>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  position: relative;
  height: 100%;
  overflow: hidden;
`;

const Title = styled.h2`
  text-align: center;
  margin: 10px 0px;
  font-size: 1.8rem;
`;

const Message = styled.p`
  font-size: 44px;
  padding: 10px 20px;
  left: 20%;
  border-radius: 5px;
  position: absolute;
  bottom: 50%;
  border: 1px solid black;
  background-color: white;
  text-align: center;
  transform: rotate(-20deg);
`;

const HyperButton = styled(UnstyledButton)`
  border-radius: 5px;
  height: 45px;
  width: 45px;
  padding-top: 2px;
  position: absolute;
  top: 50px;
  right: 10px;
  border: 1px solid black;
  font-size: 18px;

  &:hover {
    background-color: lightgrey;
  }
`;

const Icon = styled.img`
  height: 40px;
  width: 40px;
`;

export default GalaxyMap;
