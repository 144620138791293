import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";

import { MiningContext } from "../MiningContext";
import { calcTotalLoad, getMaxCargoSize } from "../../Helpers/Utils";
import { RESOURCE_DATA } from "../../data/gameData";

import ProgressBar from "../ProgressBar";

const Mining = () => {
  const { completed, setIsMining, planetData } = React.useContext(
    MiningContext
  );
  const { shipData, inventory } = useSelector((state) => state.gameData);

  const inventoryFull = calcTotalLoad(inventory) >= getMaxCargoSize(shipData);

  const resourceArray = Object.keys(planetData.resources);

  return (
    <Wrapper>
      <Title>{completed > 0 ? "Drillin'" : "Ready to get workin'"}</Title>
      <ProgressBar completed={completed} />
      <Resources>Resources remaining:</Resources>
      {resourceArray.map((item) => {
        return (
          <p key={item}>
            {RESOURCE_DATA[item].name}: {planetData.resources[item]}
          </p>
        );
      })}
      {completed === 0 && resourceArray.length >= 1 && (
        <DrillButton
          disabled={inventoryFull}
          onClick={() => {
            setIsMining(true);
          }}
        >
          {inventoryFull ? "Inventory Full!" : "start drillin'"}
        </DrillButton>
      )}
      {resourceArray.length === 0 && "Empty!"}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  border: 1px solid black;
  width: 50%;
  height: 200px;
  padding: 10px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
const Title = styled.h3``;

const Resources = styled.p`
  margin: 10px 0 10px 0;
  margin-bottom: 5px;
  font-weight: bold;
`;

const DrillButton = styled.button`
  height: 40px;
  margin-top: 5px;
  align-self: center;
  font-size: 18px;
  border-radius: 300px;
  border: 2px transparent;
  padding: 5px 10px 8px;
  background-color: rgba(0, 0, 0, 0.18);

  width: 150px;
  transition: all 100ms;

  &:hover {
    transform: scale(1.1);
    background-color: rgba(0, 0, 0, 0.12);
  }
`;

export default Mining;
